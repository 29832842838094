import './App.css';
import ClickerGame from './ClickerGame';

function GameContainer() {
	return (
		<div className="App">
			<ClickerGame/>
		</div>
	);
}

export default GameContainer;